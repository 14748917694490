import classnames from "classnames";
import _ from "lodash";
import React from "react";
import { MISSING_HOUSE_NUMBER } from "../../utils/constants";
import LocationPinIcon from "../icons/LocationPin.svg";
import SelectArrowButton from "../icons/SelectArrow.svg";
import List from "../List";
import * as styles from "./index.module.scss";

class AddressListItem extends React.Component {
  state = {
    geocoded: false,
    loadingGeocode: false,
    isValid: true,
  };

  tries = 0;

  componentWillUnmount() {
    this.cancelGeocoding = true;
    clearTimeout(this.delayedGeocoding);
  }

  geocode = place =>
    new Promise((resolve, reject) => {
      const Geocoder = this.props.google.isLoaded && new window.google.maps.Geocoder();
      console.log("geocode address for place:", place);
      Geocoder.geocode({ placeId: place.place_id }, (geocodes, status) => {
        console.log({ geocodes, status });
        if (status === "OK" && !this.cancelGeocoding) {
          return resolve(_.head(geocodes));
        } else {
          return resolve(null);
        }
      });
    });

  getPlaceDetails = place =>
    new Promise((resolve, reject) => {
      const PlacesService = new this.props.google.maps.places.PlacesService(
        document.getElementById("placeDetails"),
      );

      PlacesService.getDetails(
        {
          fields: ["geometry.location", "address_components"],
          placeId: place.place_id,
        },
        (placeResult, status) => {
          console.log({ placeResult });
          if (status === "OK" && !this.cancelGeocoding) {
            return resolve(placeResult);
          } else {
            return resolve(null);
          }
        },
      );
    });

  onSelected = () => {
    if (this.props.geocodeOnSelect) {
      this.setState({ loadingGeocodeOnSelect: true });
      this.geocode(this.props.place)
        .then(geocode => {
          this.setState({ loadingGeocodeOnSelect: false });
          if (geocode) {
            this.props.onSelected({ ...geocode, ...this.props.place });
          }
        })
        .catch(console.error);
    } else {
      this.props.onSelected({ ...this.state.geocode, ...this.props.place });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (
      !this.props.resetHighlightedAddressSelected &&
      nextProps.resetHighlightedAddressSelected
    ) {
      this.onSelected();
      nextProps.resetHighlightedAddressSelected();
    }
  }

  getPlaceId = () => {
    return `place_${this.props.place.place_id}`;
  };

  render() {
    if (!this.state.isValid || this.state.geocoding) {
      return null;
    }

    const {
      accentColor,
      T,
      place,
      accurateOnly,
      placeIdToLink,
      isHighlighted,
      appStyles,
      onMouseOver = _.noop,
      showHouseNumberInput,
      isLast = false,
    } = this.props;

    const errorMessage = accurateOnly ? place.error : null;
    const { inputBorderRadius, cardBorderRadius } = appStyles;
    const addressInputBorderRadius = _.isNumber(inputBorderRadius)
      ? inputBorderRadius
      : cardBorderRadius || 0;
    return (
      <List.Item
        style={{
          borderColor: accentColor,
          ...(isHighlighted && {
            background: appStyles.accentColor,
            color: appStyles.backgroundColor,
          }),
          ...(isLast && {
            borderBottomLeftRadius: addressInputBorderRadius,
            borderBottomRightRadius: addressInputBorderRadius,
          }),
        }}
        to={
          !errorMessage &&
          placeIdToLink &&
          placeIdToLink({ ...place, geocode: this.state.geocode })
        }
        onClick={
          errorMessage
            ? errorMessage === MISSING_HOUSE_NUMBER &&
              showHouseNumberInput(place.description)
            : this.onSelected
        }
        id={this.getPlaceId()}
        onMouseOver={onMouseOver}
        refEl={this.props.refEl}
        classNames={styles.AddressListItem}
      >
        <div className={styles.AddressListItemContent}>
          <div
            className={classnames(
              styles.AddressLabel,
              this.props.rtl && styles.RTL,
            )}
          >
            {!placeIdToLink && <LocationPinIcon />}
            {place.description}
          </div>
          {errorMessage && (
            <div className={styles.ErrorMessage}>{T(errorMessage)}</div>
          )}
          {isLast && (
            <div
              className={styles.GoogleLogo}
              style={{
                borderTop: "0.5px solid rgba(0,0,0,0.1)",
                marginTop: 12,
              }}
            >
              <img
                style={{ objectFit: "contain", height: 12 }}
                height={12}
                src="/googleMaps/light.png"
              />
            </div>
          )}
        </div>
        {!errorMessage && placeIdToLink && (
          <span>
            <SelectArrowButton />
          </span>
        )}
      </List.Item>
    );
  }
}

export default AddressListItem;
